<template>
  <section class="app-content is-flex is-flex-direction-column">
    <WorkoutFilter title="Map" @apply="onApplyFilter" @cancel="onCancelFilter" />
    <div id="mapContainer" class="is-flex-grow-1"></div>
  </section>
</template>
<script>

import cacheMiddleware from '@/services/api/middleware/cache';
import resolveMap from '@/services/map/resolver';
import WorkoutFilter from '@/components/WorkoutFilter.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Map',
  components: {
    WorkoutFilter,
  },
  data() {
    return this.getInitialState();
  },
  async mounted() {
    this.$store.dispatch('workoutFilter/setPending');
    await Promise.all([
      this.initMap(),
      this.$store.dispatch('sport/getAll'),
    ]);

    this.fetchWorkouts();
  },
  async beforeUnmount() {
    this.clear({ destroyMap: true });
  },
  computed: {
    workoutCount() {
      return this.workoutStartTimes.length;
    },
    ...mapGetters('workoutFilter', [
      'filterObject',
    ]),
    ...mapState('sport', {
      sports: 'items',
    }),
  },
  methods: {
    async initMap() {
      this.map = await resolveMap();
      await this.map.init('mapContainer', 52.06954099, 19.48026829, 6);
    },
    async fetchWorkouts() {
      this.$store.dispatch('workoutFilter/setLoadingStarted', { isCancellable: true });
      await this.fetchWorkoutsMetaData();
      for (let i = 0; i < this.workoutStartTimes.length; i += 1) {
        if (this.isCancelled) {
          this.clear();
          this.$store.dispatch('workoutFilter/setLoadingStopped');
          break;
        }
        // don't want to generate few thousands of promises
        // don't have time for chunks
        // eslint-disable-next-line no-await-in-loop
        const workout = await this.$api('workout/get', { startTime: this.workoutStartTimes[i] }, [cacheMiddleware]);
        const sport = this.sports[workout.sport.id];
        const mapPolyline = this.map.addEncodedPolyline(workout.encodedPolyline, sport ? sport.color : '#ffffff');
        const content = `<a href="/workouts/${workout.startTime}">${workout.startTime}</a>`;
        this.map.addPopup(mapPolyline, content);
        this.updateProgress();
      }
      this.$store.dispatch('workoutFilter/setLoadingStopped');
    },
    async fetchWorkoutsMetaData(before) {
      const filters = {};
      Object.assign(filters, this.filterObject);

      filters.limit = 100;
      if (before) {
        filters.before = before;
      }

      const data = await this.$api('workout/find', { filters });
      this.workoutStartTimes.push(...data.results.map((workout) => workout.startTime));
      if (data.hasMore) {
        await this.fetchWorkoutsMetaData(this.workoutStartTimes.slice(-1).pop());
      }
    },
    updateProgress() {
      this.fetchedCount += 1;
      const progress = this.fetchedCount / this.workoutStartTimes.length;
      this.$store.dispatch('workoutFilter/setProgress', progress);
    },
    onApplyFilter() {
      this.clear();
      this.fetchWorkouts();
    },
    onCancelFilter() {
      this.isCancelled = true;
    },
    clear({ destroyMap = false } = {}) {
      this.map.clear();
      if (destroyMap) {
        this.map.destroy();
      }

      Object.assign(this, this.getInitialState());
    },
    getInitialState() {
      return {
        workoutStartTimes: [],
        fetchedCount: 0,
        isCancelled: false,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import '~bulma';

.app-content {
  @include from($tablet) {
    flex-grow: 1;
  }
}
#mapContainer {
  width: 100%;
  z-index: 1;
}
</style>
