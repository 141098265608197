import prettyMilliseconds from 'pretty-ms';

export default {
  distanceMeters: {
    baseLabel: 'Distance (km)',
    formatBase(val) {
      return Math.round(val / 1000);
    },
    formatWithUnit(val) {
      return `${this.formatBase(val)} km`;
    },
  },
  durationSeconds: {
    baseLabel: 'Duration (h)',
    formatBase(val) {
      return Math.round(val / 3600);
    },
    formatWithUnit(val) {
      return prettyMilliseconds(val * 1000, { unitCount: 2 });
    },
  },
};
