<template>
  <SettingsMenuWrapper>
    <h1 class="title">Change Password</h1>
    <div class="field">
      <label class="label">Current password</label>
      <div class="control">
        <input class="input" type="password" v-model="currentPassword">
      </div>
    </div>
    <div class="field">
      <label class="label">New password</label>
      <div class="control">
        <input class="input" type="password" v-model="newPassword">
      </div>
    </div>
    <div class="field">
      <label class="label">Confirm New password</label>
      <div class="control">
        <input class="input" type="password" v-model="confirmNewPassword">
      </div>
    </div>
    <div class="field">
      <p class="control">
          <AsyncButton buttonClass="is-primary" @handle="handleChangePassword">
          Save
        </AsyncButton>
      </p>
    </div>
  </SettingsMenuWrapper>
</template>

<script>
import AsyncButton from '@/components/AsyncButton.vue';
import SettingsMenuWrapper from '@/components/settings/MenuWrapper.vue';

export default {
  name: 'Profile',
  components: {
    SettingsMenuWrapper,
    AsyncButton,
  },
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    };
  },
  methods: {
    async handleChangePassword() {
      try {
        if (this.newPassword !== this.confirmNewPassword) {
          return this.$store.dispatch('notification/setError', 'New password and Confirm New password does not match');
        }
        await this.$api('user/changePassword', {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
        });
        return this.$store.dispatch('notification/setSuccess', 'Password successfully updated.');
      } catch (error) {
        return this.$store.dispatch('notification/setError', error.getMessage());
      }
    },
  },
};
</script>
