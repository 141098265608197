<template>
   <div>
       <StatsBarChart statsProperty="distanceMeters" />
   </div>

</template>

<script>
import StatsBarChart from '@/components/stats/StatsBarChart.vue';

export default {
  name: 'YearlyDistanceStats',
  components: {
    StatsBarChart,
  },
};
</script>
