<template>
  <aside class="menu">
    <p class="menu-label">
      Distance
    </p>
    <ul class="menu-list">
      <li>
         <router-link
          active-class="is-active"
          :to="{ name: 'MonthlyDistanceStats' }"
        >
          Monthly
          </router-link>
      </li>
      <li>
        <router-link
          active-class="is-active"
          :to="{ name: 'YearlyDistanceStats' }"
        >
          Yearly
        </router-link>
      </li>
      <li>
        <router-link
          active-class="is-active"
          :to="{ name: 'TotalDistanceStats' }"
        >
          Total
        </router-link>
      </li>
    </ul>
    <p class="menu-label">
      Duration
    </p>
    <ul class="menu-list">
      <li>
         <router-link
          active-class="is-active"
          :to="{ name: 'MonthlyDurationStats' }"
        >
          Monthly
          </router-link>
      </li>
      <li>
        <router-link
          active-class="is-active"
          :to="{ name: 'YearlyDurationStats' }"
        >
          Yearly
        </router-link>
      </li>
      <li>
        <router-link
          active-class="is-active"
          :to="{ name: 'TotalDurationStats' }"
        >
          Total
        </router-link>
      </li>
    </ul>
  </aside>
</template>
