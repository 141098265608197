<template>
  <section class="app-content">
    <div class="columns">
      <div class="column is-one-quarter">
        <StatsMenu />
      </div>
      <div class="column">
        <h1 class="title">Stats</h1>
        <router-view/>
      </div>
    </div>
  </section>
</template>

<script>
import StatsMenu from '@/components/stats/Menu.vue';

export default {
  name: 'Stats',
  components: {
    StatsMenu,
  },
  async created() {
    await this.$store.dispatch('sport/getAll');
    await this.$store.dispatch('workoutStats/getAll');
  },
};
</script>
