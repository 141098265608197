<template>
  <section class="app-content">
    <h1 class="title">Sport</h1>
    <div class="field">
      <label class="label">Display Name</label>
      <div class="control">
        <input class="input" type="text" v-model="sport.displayName">
      </div>
    </div>
    <div class="field">
      <label class="label">Color</label>
      <div class="control">
        <input class="input" type="color" v-model="sport.color">
      </div>
    </div>
    <label class="label">Import names</label>
    <div class="field is-grouped is-grouped-multiline">
      <div v-for="importName in sport.importNames" :key="importName" class="control">
        <div class="tags has-addons">
          <span class="tag"> {{ importName }}</span>
          <a class="tag is-delete" @click="deleteImportName(importName)"></a>
        </div>
      </div>
    </div>
    <div class="field has-addons">
      <div class="control">
        <input class="input" ref="importNameInput" type="text" placeholder="Add import name">
      </div>
      <div class="control">
        <a class="button" @click="addImportName">
          Add
        </a>
      </div>
    </div>
    <div class="field">
      <p class="control">
        <AsyncButton buttonClass="is-primary" @handle="handleSave">
          Save
        </AsyncButton>
      </p>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import AsyncButton from '@/components/AsyncButton.vue';

export default {
  name: 'Sport',
  computed: {
    ...mapGetters('sport', ['getById']),
  },
  components: {
    AsyncButton,
  },
  data() {
    return {
      sport: {},
      initialImportNames: [],
    };
  },
  async created() {
    const sport = await this.$store.dispatch('sport/fetchDetailed', this.$route.params.sportId);

    if (!sport) {
      const pathSegments = this.$route.path.substring(1).split('/');
      const { query } = this.$route;
      this.$router.push({ name: 'NotFound', params: { pathSegments }, query });
    }

    Object.assign(this.sport, sport);
    this.initialImportNames = [...sport.importNames];
  },
  methods: {
    async handleSave() {
      try {
        await Promise.all(this.getAddedImportNamePromises());
        await Promise.all(this.getDeletedImportNamePromises());
        await this.$store.dispatch('sport/update', this.sport);
        this.$router.push({ name: 'Sports' });
      } catch (error) {
        this.$store.dispatch('notification/setError', error.getMessage());
      }
    },
    deleteImportName(importName) {
      this.sport.importNames.splice(this.sport.importNames.indexOf(importName), 1);
    },
    addImportName() {
      const importName = this.$refs.importNameInput.value;
      if (importName.length && !this.sport.importNames.includes(importName)) {
        this.sport.importNames.push(importName);
        this.$refs.importNameInput.value = '';
      }
    },
    getAddedImportNames() {
      return this.sport.importNames.filter((name) => !this.initialImportNames.includes(name));
    },
    getAddedImportNamePromises() {
      return this.getAddedImportNames().map(
        (importName) => this.$store.dispatch('sport/addImportName', { sportId: this.sport.id, importName }),
      );
    },
    getDeletedImportNames() {
      return this.initialImportNames.filter((name) => !this.sport.importNames.includes(name));
    },
    getDeletedImportNamePromises() {
      return this.getDeletedImportNames().map(
        (importName) => this.$store.dispatch('sport/deleteImportName', { sportId: this.sport.id, importName }),
      );
    },
  },
};
</script>
