<template>
  <div>
    <p> Total: {{ totalFormatted }} </p>
    <StatsTotalPieChart statsProperty="durationSeconds" />
  </div>
</template>

<script>
import StatsTotalPieChart from '@/components/stats/StatsTotalPieChart.vue';
import statsFormatter from '@/services/stats/formatter';
import { mapState } from 'vuex';

export default {
  name: 'TotalDurationStats',
  components: {
    StatsTotalPieChart,
  },
  computed: {
    ...mapState('workoutStats', {
      workoutStats: 'items',
    }),
    total() {
      return this.workoutStats.reduce((prev, stats) => prev + stats.durationSeconds.total, 0);
    },
    totalFormatted() {
      return statsFormatter.durationSeconds.formatWithUnit(this.total);
    },
  },
};
</script>
