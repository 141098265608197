<template>
  <div>
    <p v-if="fetched"> Total: {{ totalFormatted }} </p>
    <p v-if="fetched"> Trips around the world: {{ tripsAroundTheWorld.toFixed(3) }} </p>
    <StatsTotalPieChart statsProperty="distanceMeters" />
  </div>
</template>

<script>
import StatsTotalPieChart from '@/components/stats/StatsTotalPieChart.vue';
import statsFormatter from '@/services/stats/formatter';
import { mapState } from 'vuex';

const equatorDistance = 40075000;

export default {
  name: 'TotalDistanceStats',
  components: {
    StatsTotalPieChart,
  },
  computed: {
    ...mapState('workoutStats', {
      workoutStats: 'items',
      fetched: 'fetched',
    }),
    total() {
      return this.workoutStats.reduce((prev, stats) => prev + stats.distanceMeters.total, 0);
    },
    totalFormatted() {
      return statsFormatter.distanceMeters.formatWithUnit(this.total);
    },
    tripsAroundTheWorld() {
      return this.total / equatorDistance;
    },
  },
};
</script>
