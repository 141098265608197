<template>
    <apexchart v-if="fetched"
        width="100%"
        height="400"
        type="pie"
        :options="chartOptions"
        :series="series"
    ></apexchart>
    <div v-else>
      Please wait...
    </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts';
import { mapGetters, mapState } from 'vuex';
import statsFormatter from '@/services/stats/formatter';

export default {
  name: 'StatsTotalPieChart',
  props: ['statsProperty'],
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    const statsPropertyFormatter = statsFormatter[this.statsProperty];

    return {
      chartOptions: {
        colors: ['#ffffff'],
        chart: {
          id: 'vuechart-sport-stats',
        },
        dataLabels: {
          enabled: true,
          formatterlolo(val) {
            console.log(val);
            return statsPropertyFormatter.format(val);
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter(val) {
              return statsPropertyFormatter.formatWithUnit(val);
            },
          },
        },
        legend: {
          position: 'bottom',
        },
      },
      series: [],
      total: 0,
    };
  },
  computed: {
    ...mapGetters('sport', {
      getSportById: 'getById',
    }),
    ...mapState('workoutStats', {
      workoutStats: 'items',
      fetched: 'fetched',
    }),
  },
  created() {
    this.setChartData();
  },
  watch: {
    workoutStats() {
      this.setChartData();
    },
  },
  methods: {
    setChartData() {
      this.setSeries();
      this.setSportColors();
      this.setLabels();
    },
    setSeries() {
      this.series = this.workoutStats
        .map((sportStats) => sportStats[this.statsProperty].total);
    },
    setSportColors() {
      const colors = this.workoutStats
        .map((sportStats) => this.getSportById(sportStats.sportId).color);
      this.chartOptions = { ...this.chartOptions, colors };
    },
    setLabels() {
      const labels = this.workoutStats
        .map((sportStats) => this.getSportById(sportStats.sportId).displayName);

      this.chartOptions = {
        ...this.chartOptions,
        labels,
      };
    },
  },
};
</script>
