<template>
  <section class="app-content">
    <h1 class="title">Sports</h1>
    <div class="table-container">
      <table class="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>Display Name</th>
              <th>Color</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="sport in sports" :key="sport.id">
              <td><span>{{ sport.displayName }}</span></td>
              <td :style="{ backgroundColor: sport.color }"></td>
              <td>
                <router-link
                  :to="{ name: 'Sport', params: {sportId: sport.id} }"
                  class="button is-small mr-2"
                >
                  <span class="icon is-small">
                    <font-awesome-icon icon="edit"></font-awesome-icon>
                  </span>
                </router-link>
                <button class="button is-small" @click.stop="onDeleteRequested(sport)">
                    <span class="icon is-small">
                      <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                    </span>
                </button>
              </td>
            </tr>
          </tbody>
      </table>
    </div>
  </section>
  <Modal
    v-if="sportToDelete"
    title="Please confirm"
    type="warning"
    is-confirm
    is-async
    @dismissed="this.onDeleteModalDismissed()"
    @confirmed="this.onDeleteModalConfirmed()"
  >
    You are about to delete {{ sportToDelete.displayName }}. Please confirm.
  </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal.vue';

export default {
  name: 'Sports',
  components: {
    Modal,
  },
  data() {
    return {
      sportToDelete: undefined,
    };
  },
  computed: {
    ...mapState('sport', {
      sports: 'items',
    }),
  },
  created() {
    this.$store.dispatch('sport/getAll');
  },
  methods: {
    onDeleteRequested(sport) {
      this.sportToDelete = sport;
    },
    onDeleteModalDismissed() {
      this.sportToDelete = undefined;
    },
    async onDeleteModalConfirmed() {
      try {
        await this.$store.dispatch('sport/delete', this.sportToDelete);
        this.$store.dispatch('notification/setSuccess', 'Sport deleted sucessfully');
      } catch (error) {
        this.$store.dispatch('notification/setError', error.getMessage());
      }
      this.sportToDelete = undefined;
    },
  },
};
</script>

<style scoped lang="scss">
  td {
    span {
      vertical-align: middle;
    }
  }
</style>
