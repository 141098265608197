<template>
  <SettingsMenuWrapper>
    <h1 class="title">Map</h1>
    <div class="field">
      <label class="label">Provider</label>
      <div class="control">
        <div class="select">
          <select v-model="provider">
            <option value="osm">OpenStreetMap</option>
            <option value="mapycz">Mapy.cz</option>
            <option value="gmaps">Google Maps</option>
          </select>
        </div>
      </div>
    </div>
  </SettingsMenuWrapper>
</template>

<script>
import SettingsMenuWrapper from '@/components/settings/MenuWrapper.vue';

export default {
  name: 'Profile',
  components: {
    SettingsMenuWrapper,
  },
  computed: {
    provider: {
      get() {
        return this.$store.state.profile.user.settings.mapProvider;
      },
      set(mapProvider) {
        this.$store.dispatch('profile/updateSettings', { mapProvider });
      },
    },
  },
};
</script>
