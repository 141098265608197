<template>
  <div>
    <StatsYearPicker :current="year" @update="updateYear" />
    <StatsBarChart statsProperty="distanceMeters" :year="year"/>
  </div>
</template>

<script>
import StatsBarChart from '@/components/stats/StatsBarChart.vue';
import StatsYearPicker from '@/components/stats/StatsYearPicker.vue';

export default {
  name: 'MonthlyDistanceStats',
  components: {
    StatsBarChart,
    StatsYearPicker,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  methods: {
    updateYear(year) {
      this.year = year;
    },
  },
};
</script>
