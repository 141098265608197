<template>
    <apexchart v-if="fetched"
        width="100%"
        height="400"
        type="bar"
        :options="chartOptions"
        :series="series"
    ></apexchart>
    <div v-else>
      Please wait...
    </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts';
import { mapGetters, mapState } from 'vuex';
import statsFormatter from '@/services/stats/formatter';

const monthLabels = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII'];

export default {
  name: 'StatsBarChart',
  props: ['year', 'statsProperty'],
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    const statsPropertyFormatter = statsFormatter[this.statsProperty];

    return {
      chartOptions: {
        colors: ['#ffffff'],
        chart: {
          id: 'vuechart-sport-stats',
          stacked: true,
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            formatter(val) {
              return statsPropertyFormatter.formatBase(val);
            },
          },
          title: {
            text: statsPropertyFormatter.baseLabel,
          },
        },
        dataLabels: {
          enabled: true,
          formatter(val) {
            return statsPropertyFormatter.formatBase(val);
          },
        },
      },
      series: [],
    };
  },
  computed: {
    ...mapGetters('sport', {
      getSportById: 'getById',
    }),
    ...mapGetters('workoutStats', ['yearsRange']),
    ...mapState('workoutStats', {
      workoutStats: 'items',
      fetched: 'fetched',
    }),
  },
  created() {
    this.setChartData();
  },
  watch: {
    workoutStats() {
      this.setChartData();
    },
    year() {
      this.setChartData();
    },
  },
  methods: {
    setChartData() {
      this.setSeries();
      this.setSportColors(this.series.map((data) => data.color));
      this.setXLabels();
    },
    setSeries() {
      this.series = this.workoutStats
        .map((sportStats) => {
          const sport = this.getSportById(sportStats.sportId);
          return {
            name: sport.displayName,
            data: this.getSportSeries(sportStats, this.statsProperty, this.year),
            color: sport.color,
          };
        })
        .filter((series) => series.data);
    },
    getSportSeries(sportStats, statsProperty, year) {
      if (year) {
        return sportStats[statsProperty].monthly[year];
      }

      return this.yearsRange.map((rangeYear) => sportStats[statsProperty].yearly[rangeYear] || 0);
    },
    setSportColors(colors) {
      this.chartOptions = { ...this.chartOptions, colors };
    },
    setXLabels() {
      const labels = this.year ? monthLabels : this.yearsRange;

      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          ...this.chartOptions.xaxis,
          categories: labels,
        },
      };
    },
  },
};
</script>
