<template>
  <SettingsMenuWrapper>
    <h1 class="title">Strava</h1>
    <div v-if="isStravaConnected">
      <p>Succesfully connected with Strava. Athlete id: {{ user.integrations.strava.athleteId }}</p>
      <a :class="{ 'is-loading': isLoading }" class="button" :href="connectUrl">Reconnect</a>
    </div>
    <div v-else>
      <a :class="{ 'is-loading': isLoading }" :href="connectUrl">
        <img src="@/assets/images/btn_strava_connectwith_orange.png"
          width="193"
          height="48"
          alt="Connect With Strava"
        />
      </a>
    </div>
  </SettingsMenuWrapper>
</template>

<script>

import SettingsMenuWrapper from '@/components/settings/MenuWrapper.vue';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'StravaConnectSettings',
  components: {
    SettingsMenuWrapper,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    if (this.$route.query.code) {
      if (!this.$route.query.scope.includes('activity:read_all')) {
        this.$store.dispatch('notification/setError', 'Activity permissions are required.');
        return;
      }

      this.authorize(this.$route.query.code);
    }
  },
  computed: {
    connectUrl() {
      const baseUrl = 'https://www.strava.com/oauth/authorize';
      const params = {
        client_id: process.env.VUE_APP_STRAVA_CLIENT_ID,
        redirect_uri: process.env.VUE_APP_STRAVA_REDIRECT_URI,
        response_type: 'code',
        approval_prompt: 'auto',
        scope: 'activity:read_all',
      };
      const queryString = new URLSearchParams(params).toString();

      return `${baseUrl}?${queryString}`;
    },
    ...mapState('profile', ['user']),
    ...mapGetters('profile', ['isStravaConnected']),
  },
  methods: {
    async authorize(code) {
      try {
        this.isLoading = true;
        await this.$api('strava/connect', { code });
        await this.$store.dispatch('profile/fetch');
        this.$store.dispatch('notification/setSuccess', 'Succesfully connected with strava.');
        this.$router.push({ name: 'StravaConnectSettings' });
      } catch (error) {
        this.$store.dispatch('notification/setError', error.getMessage());
      }
      this.isLoading = false;
    },
  },
};
</script>
