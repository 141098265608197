<template>
  <div>
    <StatsBarChart statsProperty="durationSeconds" />
  </div>
</template>

<script>
import StatsBarChart from '@/components/stats/StatsBarChart.vue';

export default {
  name: 'YearlyDurationStats',
  components: {
    StatsBarChart,
  },
};
</script>
