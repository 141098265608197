<template>
  <SettingsMenuWrapper>
    <h1 class="title">Profile</h1>
    <div class="field">
      <label class="label">Username</label>
      <div class="control">
        <input class="input" type="text" v-model="user.username" disabled>
      </div>
    </div>
    <div class="field">
      <label class="label">Email</label>
      <div class="control">
        <input class="input" type="text" v-model="user.email" disabled>
      </div>
    </div>
  </SettingsMenuWrapper>
</template>

<script>
import { mapState } from 'vuex';
import SettingsMenuWrapper from '@/components/settings/MenuWrapper.vue';

export default {
  name: 'Profile',
  components: {
    SettingsMenuWrapper,
  },
  computed: {
    ...mapState('profile', ['user']),
  },
};
</script>
