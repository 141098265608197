<template>
  <aside class="menu">
    <p class="menu-label">
      Settings
    </p>
    <ul class="menu-list">
      <li>
         <router-link
          active-class="is-active"
          :to="{ name: 'ProfileSettings' }"
        >
          Profile
          </router-link>
      </li>
        <router-link
          active-class="is-active"
          :to="{ name: 'MapSettings' }"
        >
          Map
      </router-link>
      <li>
        <router-link
          active-class="is-active"
          :to="{ name: 'ChangePasswordSettings' }"
        >
          Change Password
        </router-link>
      </li>
    </ul>
    <p class="menu-label">
      Connect
    </p>
    <ul class="menu-list">
      <li>
         <router-link
          active-class="is-active"
          :to="{ name: 'StravaConnectSettings' }"
        >
          Strava
          </router-link>
      </li>
    </ul>
  </aside>
</template>
