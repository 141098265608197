<template>
  <section class="app-content">
    <div class="columns">
      <div class="column is-one-quarter">
        <SettingsMenu />
      </div>
      <div class="column">
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
import SettingsMenu from '@/components/settings/Menu.vue';

export default {
  name: 'SettingsMenuWrapper',
  components: {
    SettingsMenu,
  },
};
</script>
