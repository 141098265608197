<template>
  <div class="section has-text-centered">
    <a v-if="hasPrev" href="#" @click.stop="setPrev">
      &lt;
    </a>
    {{ current }}
    <a v-if="hasNext" href="#" @click.stop="setNext">
      &gt;
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'StatsYearPicker',
  props: ['current'],
  computed: {
    ...mapGetters('workoutStats', ['minYear', 'maxYear']),
    hasPrev() {
      return this.minYear && this.minYear < Number(this.current);
    },
    hasNext() {
      return this.maxYear && this.maxYear > Number(this.current);
    },
  },
  methods: {
    setPrev() {
      this.$emit('update', this.current - 1);
    },
    setNext() {
      this.$emit('update', this.current + 1);
    },
  },
};
</script>
